<template>
    <b-card
            no-body
            class="border-primary"
    >
        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
            <h5 class="mb-0">
                سطح کاربری
            </h5>
            <b-badge variant="light-primary">
                {{level.name}}
            </b-badge>
<!--            <small class="text-muted w-100">آپدیت شذه در تاریخ : 1 اردیبهشت 1400</small>-->
        </b-card-header>

        <b-card-body>
            <ul class="list-unstyled my-1">
                <!--<li>
                    <span class="align-middle">زیر مجموعه های مورد نیاز : 0</span>
                </li>-->
                <li class="my-25">
                    <span class="align-middle">حداقل معاملات تا این سطح بعد : {{$toLocal(pervLevel.tradeAmount)}} تومان</span>
                </li>
                <li class="my-25">
                    <span class="align-middle">حداقل معاملات تا سطح بعد : {{$toLocal(level.tradeAmount)}} تومان</span>
                </li>
                <!--<li>
                    <span class="align-middle">محدودیت برداشت : تا {{[100000].toLocaleString()}} تومان</span>
                </li>-->
                <li>
                    <span class="align-middle">کارمزد سفارشات : {{level.wagePercent}}%</span>
                </li>
                <!--<li>
                    <span class="align-middle">محدودیت حساب بانکی : 1</span>
                </li>-->
            </ul>
        </b-card-body>

    </b-card>
</template>

<script>
    import {
        BCard,
        BCardHeader,
        BCardBody,
        BBadge,
        // BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BCard,
            BCardHeader,
            BCardBody,
            BBadge,
            // BButton,
        },
        props:['level','pervLevel']
    }
</script>

<style>

</style>
