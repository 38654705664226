<template>
    <b-modal
            @show="$emit('resetModal')"
            @hidden="$emit('hidden')"
            @cancel="$emit('handleAuth',[datas.bankAccountDTO.id,false])"
            @ok="$emit('handleAuth',[datas.bankAccountDTO.id,true])"
            centered
            title="احراز هویت"
            ok-title="تایید حساب"
            cancel-title="عدم تایید حساب"
            ok-variant="success"
            cancel-variant="danger"
            v-model="bankModal">
        <b-card
                :img-src="image"
                img-alt="Card image cap"
                :title="'اطلاعات تطبیقی برای کد ملی : ' + nationalId"
                img-top
        >
            <b-card-body class="p-0">
                <div
                        class="employee-task d-flex justify-content-between align-items-center"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-75">
                            <feather-icon size="20" icon="BoldIcon"/>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                نام بانک در سیستم
                            </h6>
                            <small>نام بانک وارد شده</small>
                        </b-media-body>
                    </b-media>
                    <b-media no-body>
                        <b-media-body class="my-auto text-right">
                            <h6 class="mb-0">
                                {{ datas.finoTechResponseOfCardToIbanDTO.bankName }}
                            </h6>
                            <small>{{ datas.bankAccountDTO.bankName }}</small>
                        </b-media-body>
                    </b-media>
                </div>
                <div
                        class="employee-task d-flex justify-content-between align-items-center my-50"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-75">
                            <feather-icon size="20" icon="CreditCardIcon"/>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                شماره کارت در سیستم
                            </h6>
                            <small>شماره کارت وارد شده</small>
                        </b-media-body>
                    </b-media>
                    <b-media no-body>
                        <b-media-body class="my-auto text-right">
                            <h6 class="mb-0">
                                {{ datas.finoTechResponseOfCardToIbanDTO.card }}
                            </h6>
                            <small>{{ datas.bankAccountDTO.cardNumber }}</small>
                        </b-media-body>
                    </b-media>
                </div>
                <div
                        class="employee-task d-flex justify-content-between align-items-center my-50"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-75">
                            <feather-icon size="20" icon="CpuIcon"/>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                شبای حساب در سیستم
                            </h6>
                            <small>شبای وارد شده</small>
                        </b-media-body>
                    </b-media>
                    <b-media no-body>
                        <b-media-body class="my-auto text-right">
                            <h6 class="mb-0">
                                {{ datas.finoTechResponseOfCardToIbanDTO.iban }}
                            </h6>
                            <small>IR{{ datas.bankAccountDTO.shebaCode }}</small>
                        </b-media-body>
                    </b-media>
                </div>
                <div
                        v-for="depositOwners in datas.finoTechResponseOfCardToIbanDTO.depositOwners"
                        :key="depositOwners.firstName"
                        class="employee-task d-flex justify-content-between align-items-center my-50"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-75">
                            <feather-icon size="20" icon="UserIcon"/>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                نام صاحب حساب در سیستم
                            </h6>
                            <small>نام وارد شده</small>
                        </b-media-body>
                    </b-media>
                    <b-media no-body>
                        <b-media-body class="my-auto text-right">
                            <h6 class="mb-0">
                                {{ depositOwners.fullName }}
                            </h6>
                            <small>{{ userInfo.firstName + ' ' + userInfo.lastName }}</small>
                        </b-media-body>
                    </b-media>
                </div>
                <div
                        class="employee-task d-flex justify-content-between align-items-center my-50"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-75">
                            <feather-icon size="20" :icon="datas.finoTechResponseOfCardToIbanDTO.depositDescription ? 'CheckIcon' : 'XIcon'"/>
                        </b-media-aside>
                        <b-media-body class="my-auto" :class="['text-' + (datas.finoTechResponseOfCardToIbanDTO.depositDescription ? 'success' : 'danger'),]">
                            <h6 class="mb-0">
                                وضعیت حساب
                            </h6>
                            <small>{{datas.finoTechResponseOfCardToIbanDTO.depositDescription ? 'فعال' : 'غیر فعال'}}</small>
                        </b-media-body>
                    </b-media>
                </div>
            </b-card-body>
        </b-card>
    </b-modal>
</template>

<script>
    import {
        BModal,
        BCard,
        BCardBody,
        BMedia,
        BMediaAside,
        BMediaBody,
    } from 'bootstrap-vue'

    import {$themeColors} from '@themeConfig'

    export default {
        name: "BankModal",
        props: ['datas', 'bankModal', 'nationalId', 'image','userInfo'],
        components: {
            BModal,
            BCard,
            BCardBody,
            BMedia,
            BMediaAside,
            BMediaBody,
            // VueApexCharts,
        },
        data() {
            return {
                chartColor: {danger: $themeColors.danger, success: $themeColors.success, warning: $themeColors.warning},
            }
        },
        computed: {
            authColor() {
                return a => a > 90 ? 'success' : a > 60 ? 'warning' : 'danger';
            },
        },
        methods: {

        },
        created() {
            
        }
    }
</script>

<style scoped>

</style>